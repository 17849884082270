import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../../helpers/config.service';
import { AuthAPIService } from '../../auth/services/auth-api.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-interests-login',
  templateUrl: './interests-login.component.html',
  styles: ``
})
export class InterestsLoginComponent implements OnInit {

  constructor(private _config: ConfigService,
    private _activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private _authApiService: AuthAPIService) { }
  ngOnInit(): void {

    this.GetCategories()
  }
  currentUser: any = this.authenticationService.currentUserValue;
  SelectedCategories: string[] = []
  loading_data: boolean = false;
  categoriesList: any[] = []
  loading: boolean = false;
  error: boolean = false;
  GetCategories() {
    this.loading_data = true;
    this._authApiService.GetCategories().subscribe({
      next: (res: any) => {
        this.loading_data = false;
        this.categoriesList = res.data.list
      },
      error: (error: any) => {
        this.loading_data = false;
      },
    })
  }


  OnSubmit() {
    this.error = false;

    let model: any = []
    this.categoriesList.forEach(element => {
      if (element?.selected?.length) {
        model = model.concat(element?.selected)
      }
    })
    if (!model.length) {
      this.error = true
      return
    }
    this.loading = true;

    this._authApiService.AddInterstes({ category_ids: model }).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.OnSkip()
      },
      error: (error: any) => {
        this.loading = false;
      },
    })
  }

  OnSkip() {
    this._config.goTo("/panel");
    this.AssignThemeColors();
  }


  AssignThemeColors() {
    let color = this.authenticationService.currentUserValue?.company?.color
    let text_color = this.authenticationService.currentUserValue?.company?.font_color
    document.documentElement.style.setProperty('--main-color', color);
    document.documentElement.style.setProperty('--main-text-color', text_color);
    console.info(`color:${color} , font_color: ${text_color}`)
  }

}
