<footer>
    <div class="help">
        <div>
            <img src="/assets/images/logo_white.svg" alt="" class="logo">
        </div>
        <ul>
            <li><a href="#">{{"home.Help & Contact"|translate}} </a></li>
            <li><a href="#"> {{"home.Frequently Asked Questions"|translate}}</a></li>
            <li><a href="#"> {{"home.Terms & Conditions"|translate}}</a></li>
            <li><a href="#"> {{"home.Privacy Policy"|translate}}</a></li>
        </ul>



        <div class="downloadApp">
            <p class="title">
                {{"home.Download our app"|translate}}
            </p>
            <img src="/assets/images/google_play.png" alt="image">
            <img src="/assets/images/appStore.png" alt="image">
        </div>
    </div>


    <div class="copy_right">
        © {{"general.All rights reserved"|translate}} 2024
    </div>
</footer>