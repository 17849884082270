import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { ConfigService } from '../../../helpers/config.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationListComponent } from '../notification-list/notification-list.component';
import { ListingModel } from '../../../features/panel/models/general';
import { NavigationService } from '../../../services/navigation.service';
import { Socket } from 'socket.io-client';
import { SocketEventType, SocketService } from '../../../services/socket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: ``
})
export class NavbarComponent implements OnInit {

  auth: any
  infoUser: any;
  language = localStorage.getItem('language') || 'en';
  nav_list: any[] = [{ name: "home.Home", path: "/panel" },
  { name: "home.Offers", path: "/panel/offers" },
  { name: "home.Vendors", path: "/panel/vendors" },
  { name: "home.News", path: "/panel/news" },
  { name: "home.Surveys", path: "/panel/survey" }]
  collapse: boolean = false;
  listOpened: boolean = false;
  currentUser: any

  notificationListModel: ListingModel = new ListingModel;
  totalUnreadNotifications: number = 0;
  subscribetions: Subscription[] = []
  notificationList_opened: boolean = false;
  constructor(
    private _config: ConfigService,
    private el: ElementRef,
    private activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private navService: NavigationService,
    private _socket: SocketService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.GetNotificationsList()
    this.subscribetions.push(
      this._socket.getDataStream<string>(SocketEventType.Notification)
        .subscribe(message => {
          console.log(message);
          if (message.data?.message) {
            this.totalUnreadNotifications += 1;
            this.openSnackBar(message.data?.message, '')
          }
        }))
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      horizontalPosition: "end",
      verticalPosition: 'bottom',
      duration: 4000
    });
  }

  changeLanguage() {
    let params = JSON.parse(JSON.stringify(this.activatedRoute.snapshot.queryParams))
    let lang = this.language == 'ar' ? 'en' : 'ar'
    params['lang'] = lang
    localStorage.setItem('language', lang);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }


  logout() {
    this.authenticationService.logout();
    this._config.goTo('/');
  }

  OpenNotificationList() {

    const dialogRef = this._dialog.open(NotificationListComponent, {
      width: "540px"
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.totalUnreadNotifications = 0;

      }
    })
  }

  GetNotificationsList() {
    let model = { ...this.notificationListModel }
    model.page += 1;
    this.navService.GetNotifiactions(model).subscribe((result) => {
      this.totalUnreadNotifications = result.data.count_unread;
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscribetions.forEach((item) => item.unsubscribe);
  }
}
