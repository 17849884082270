import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { SocketService } from '../../services/socket.service';

@Component({
  selector: 'app-panelLayout',
  templateUrl: './panelLayout.component.html'
})
export class PanelLayoutComponent implements OnInit {

  constructor(private _socket: SocketService,
    private _authService: AuthenticationService) {
    this._socket.onOpenSocket();
    this._socket.setupSocketEvents()
    let model = { userID: _authService.currentUserValue?.id }
    this._socket.socket.emit("join", model)

  }

  ngOnInit() {
  }

}
